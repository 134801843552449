import "./Modal.scss";
import React from "react";
import { Button, Modal as AntModal } from "antd";
import { CloseCircle } from "iconsax-react";

export default function Modal({
  maskClosable = true,
  onCancel = () => {},
  title,
  children,
  titleProps = {},
  headerProps = {},
  bodyProps = { style: {} },
  showHeader = true,
  wrapClassName,
  visible,
  closeable = true,
  destroyOnClose,
  mainContentProps = {},
  ...props
}) {
  return (
    <AntModal
      open={visible}
      centered
      destroyOnClose={destroyOnClose}
      bodyStyle={{
        padding: 0,
        width: "100%",
        background: "#fff",
        borderRadius: 12,
        ...bodyProps.style,
      }}
      footer=""
      wrapClassName={`modal_root ${wrapClassName}`}
      maskClosable={maskClosable}
      onCancel={maskClosable ? onCancel : undefined}
      {...props}
      closable={false}
    >
      <div>
        {showHeader && (title || closeable) && (
          <div className="my_header" {...headerProps}>
            <h4 className="mb-0" {...titleProps}>
              {title}
            </h4>
            {closeable && (
              <Button
                onClick={() => {
                  onCancel();
                }}
                type="link"
                className="close_but"
              >
                <CloseCircle size="22" color="#677C99" />
              </Button>
            )}
          </div>
        )}
        <div {...mainContentProps}>{children}</div>
      </div>
    </AntModal>
  );
}
