import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfirmationServiceProvider } from "./components/Confirmation/ConfirmationService";
import { ToastContainer } from "react-toastify";
import { Danger, InfoCircle, TickCircle, Warning2 } from "iconsax-react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Router from "./Router";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

function getToastIcon({ type }) {
  switch (type) {
    case "success":
      return <TickCircle size="32" color="#64BC26" />;
    case "error":
      return <Danger size="32" color="#FF8A65" />;
    case "info":
      return <InfoCircle size="32" color="#1EA5FC" />;
    case "warning":
      return <Warning2 size="32" color="#feefa4" />;
    default:
      return "🤷‍♂️";
  }
}

function getToastClassName({ type }) {
  switch (type) {
    case "success":
      return "toastify-success";
    case "error":
      return "toastify-error";
    case "info":
      return "toastify-info";
    case "warning":
      return "toastify-warning";
    default:
      return "toastify-default";
  }
}

function App() {
  return (
    <ConfirmationServiceProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Router />
        </BrowserRouter>
        <ToastContainer
          className="toastify-container"
          bodyClassName={getToastClassName}
          icon={getToastIcon}
          hideProgressBar
          autoClose={2500}
          position="bottom-right"
        />
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </ConfirmationServiceProvider>
  );
}

export default App;
