const styleDef = [];

// video ////////////////////////////////////
const videoStyle = {
  labelColor: "#5a228b",
  foldColor: "#360d59",
  color: "#5a228b",
  type: "video",
};
const videoExtList = [
  "avi",
  "3g2",
  "3gp",
  "aep",
  "asf",
  "flv",
  "m4v",
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "ogv",
  "pr",
  "swfw",
  "webm",
  "wmv",
  "swf",
  "rm",
];

styleDef.push([videoStyle, videoExtList]);

// image ////////////////////////////////////
const imageStyle = {
  labelColor: "#00806C",

  foldColor: "#025648",
  color: "#00806C",
  type: "image",
};

const imageExtList = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "bmp",
  "tif",
  "tiff",
  "webp",
  "ico",
];

styleDef.push([imageStyle, imageExtList]);

// zip ////////////////////////////////////
const zipStyle = {
  labelColor: "#f7e700",
  labelTextColor: "#000",
  glyphColor: "#000",
  foldColor: "#f7e700",
  color: "#f7e700",
  type: "compressed",
};

const zipExtList = ["zip", "zipx", "7zip", "tar", "sitx", "gz", "rar", "tz"];

styleDef.push([zipStyle, zipExtList]);

// audio ////////////////////////////////////
const audioStyle = {
  labelColor: "#cf2f74",
  foldColor: "#7f1442",

  color: "#cf2f74",
  type: "audio",
};

const audioExtList = [
  "aac",
  "aif",
  "aiff",
  "flac",
  "m4a",
  "mid",
  "midi",
  "cda",
  "mp3",
  "ogg",
  "wav",
  "wma",
  "wpl",
  "mpa",
];

styleDef.push([audioStyle, audioExtList]);

// text ////////////////////////////////////
const textStyle = {
  labelColor: "#143c99",
  foldColor: "#143c99",
  color: "#143c99",
};

const textExtList = [
  "cue",
  "odt",
  "md",
  "rtf",
  "txt",
  "tex",
  "wpd",
  "wps",
  "xlr",
  "fodt",
];

styleDef.push([textStyle, textExtList]);

// system ////////////////////////////////////
const systemStyle = {
  labelColor: "#111",
};

const systemExtList = ["exe", "ini", "dll", "plist", "sys"];

styleDef.push([systemStyle, systemExtList]);

// srcCode ////////////////////////////////////
const srcCodeStyle = {
  labelColor: "#787878",
  foldColor: "#787878",
  color: "#787878",
  type: "code",
};

const srcCodeExtList = [
  "asp",
  "aspx",
  "c",
  "cpp",
  "cs",
  "css",
  "scss",
  "py",
  "json",
  "htm",
  "html",
  "java",
  "yml",
  "php",
  "js",
  "ts",
  "rb",
  "jsx",
  "tsx",
];

styleDef.push([srcCodeStyle, srcCodeExtList]);

// vector ////////////////////////////////////
const vectorStyle = {
  labelColor: "#ff8000",
  foldColor: "#a35406",
  color: "#ff8000",
  type: "vector",
};

const vectorExtList = ["dwg", "dxf", "ps", "svg", "eps"];

styleDef.push([vectorStyle, vectorExtList]);

// font ////////////////////////////////////
const fontStyle = {
  labelColor: "#555",
  foldColor: "#323232",
  color: "#555",
  type: "font",
};

const fontExtList = ["fnt", "ttf", "otf", "fon", "eot", "woff"];

styleDef.push([fontStyle, fontExtList]);

// objectModel ////////////////////////////////////
const objectModelStyle = {
  labelColor: "#bf6a02",
  foldColor: "#bf6a02",
};

const objectModelExtList = ["3dm", "3ds", "max", "obj", "pkg"];

styleDef.push([objectModelStyle, objectModelExtList]);

// doc ////////////////////////////////////
const docStyle = {
  labelColor: "#2c5898",
  foldColor: "#254a80",
  color: "#2c5898",
  type: "document",
};

const docExtList = ["doc", "docx", "odt", "xlr"];

styleDef.push([docStyle, docExtList]);

// sheet ////////////////////////////////////
const sheetStyle = {
  labelColor: "#1a754c",
  foldColor: "#16613f",
  color: "#1a754c",

  type: "spreadsheet",
};

const sheetExtList = ["csv", "fods", "ods", "xlr", "xlsm", "xls", "xlsx"];

styleDef.push([sheetStyle, sheetExtList]);

// presentation ////////////////////////////////////
const presentationStyle = {
  color: "#D14423",
  labelColor: "#D14423",
  foldColor: "#ab381d",
  type: "presentation",
};

const presentationExtList = ["pps", "ppt", "pptx", "odp"];

styleDef.push([presentationStyle, presentationExtList]);

// / ///////////////////////////////////////////////

// Style by extension /////////////////////////////////

const defaultStyle = {
  pdf: {
    color: "#D93831",
    foldColor: "#6a1411",
    type: "acrobat",
  },
  psb: {
    color: "#34364e",
    gradientColor: "#34364e",
    labelColor: "#34364e",
    labelTextColor: "#31c5f0",
    radius: 2,
    foldColor: "#31c5f0",
  },
};

// / ///////////////////////////////////////////////

function createStyleObj(extList, styleObj) {
  return Object.fromEntries(extList.map((ext) => [ext, styleObj]));
}

export const styleDefObj = styleDef.reduce(
  (acc, [fileStyle, fileExtList]) => ({
    ...acc,
    ...createStyleObj(fileExtList, fileStyle),
  }),
  defaultStyle
);
