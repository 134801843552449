import { useMutation, useQuery } from "@tanstack/react-query";

export const useRevisionFolders = <T, K>({
  taskid,
  revisionid,
  ukey,
  uvalid,
}) => {
  return useQuery<T, K>(
    ["shared_revision", taskid, revisionid, ukey, uvalid],
    async () => {
      return await fetch(
        `${process.env.REACT_APP_API_URL}/customersharedrevision`,
        {
          method: "post",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            taskid,
            revisionid,
            ukey,
            uvalid,
            parentfolder: 0,
            masterfolder: 0,
            viewtype: "1",
          }),
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          if (res.ok) {
            return res.json();
          }
        })
        .then((resData) => {
          if (resData.success === 0) {
            throw new Error(
              "The resource you are looking for is either expired or invalid"
            );
          }
          return resData;
        })
        .catch(() => {
          throw new Error(
            "The resource you are looking for is either expired or invalid."
          );
        });
    }
  );
};

export const useFolderSubFolders = <T, K>({
  viewtype,
  taskid,
  revisionid,
  folderid,
  ukey,
  uvalid,
  subFolderId,
}) => {
  return useQuery<T, K>(
    ["shared_folder", taskid, revisionid, folderid, subFolderId, ukey, uvalid],
    async () => {
      return await fetch(
        `${process.env.REACT_APP_API_URL}/customersharedrevision`,
        {
          method: "post",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            taskid,
            revisionid,
            ukey,
            uvalid,
            parentfolder: subFolderId,
            masterfolder: folderid,
            viewtype,
          }),
        }
      )
        .then((res) => {
          const resData = res.json();
          if (res.ok) {
            return resData;
          }
          throw new Error(
            "The resource you are looking for is either expired or invalid"
          );
        })
        .then((resData) => {
          if (resData.success === 0) {
            throw new Error(
              "The resource you are looking for is either expired or invalid"
            );
          }
          return resData;
        })
        .catch(() => {
          throw new Error(
            "The resource you are looking for is either expired or invalid"
          );
        });
    }
  );
};

export const useFolderFiles = <T, K>({
  subFolderId,
  viewtype,
  taskid,
  revisionid,
  folderid,
  ukey,
  uvalid,
}) => {
  return useQuery<T, K>(
    [
      "shared_folder_files",
      taskid,
      revisionid,
      folderid,
      subFolderId,
      ukey,
      uvalid,
    ],
    async () => {
      return await fetch(
        `${process.env.REACT_APP_API_URL}/customersharedrevisionfiles`,
        {
          method: "post",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            taskid,
            revisionid,
            ukey,
            uvalid,
            parentfolder: subFolderId,
            masterfolder: folderid,
            viewtype,
          }),
        }
      )
        .then((res) => {
          const resData = res.json();
          if (res.ok) {
            return resData;
          }
          throw new Error(
            "The resource you are looking for is either expired or invalid."
          );
        })
        .then((resData) => {
          if (resData.success === 0) {
            throw new Error(
              "The resource you are looking for is either expired or invalid"
            );
          }
          return resData;
        })
        .catch(() => {
          throw new Error(
            "The resource you are looking for is either expired or invalid."
          );
        });
    }
  );
};

export const useFolderBreadCrumbs = ({ revisionid, folderid }) => {
  return useQuery(["shared_breadcrumbs", revisionid, folderid], async () => {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tasksourcebreadcrumb`,
      {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          revisionid,
          parentid: folderid,
        }),
      }
    )
      .then((res) => {
        const resData = res.json();
        if (res.status === 200) {
          return resData;
        }
        throw new Error(
          "The resource you are looking for is either expired or invalid."
        );
      })
      .catch(() => {
        throw new Error(
          "The resource you are looking for is either expired or invalid."
        );
      });
  });
};

type DownloadFolderParams = {
  isFromKimpHub: boolean;
  medias: [
    {
      path: string;
      media: {
        file_name: string;
        file_path: string;
      }[];
    }
  ];
};

type DownloadFolderDataGetParams = {
  revisionid: string;
  folderName: string;
  folderId: number;
};

export interface DownloadDataResponse {
  file_structure: Record<string, string[]>;
  status: number;
}

export const useFolderDownloadData = (options) => {
  return useMutation<DownloadDataResponse, any, DownloadFolderDataGetParams>(
    async ({ revisionid, folderName, folderId }) => {
      return await fetch(`${process.env.REACT_APP_API_URL}/downloadsourcezip`, {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          revisionId: revisionid,
          currentFolderName: folderName,
          currentFolderId: folderId,
        }),
      })
        .then((res) => {
          const resData = res.json();
          if (res.status === 200) {
            return resData as unknown as DownloadDataResponse;
          }
          throw new Error(
            "The resource you are looking for is either expired or invalid."
          );
        })
        .catch(() => {
          throw new Error(
            "The resource you are looking for is either expired or invalid."
          );
        });
    },
    options
  );
};

export const useDownloadFolder = (options) => {
  return useMutation<any, any, DownloadFolderParams>(async (data) => {
    return await fetch(process.env.REACT_APP_FOLDER_DOWNLOAD_URL, {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        const resData = res.json();
        if (res.status === 200) {
          return resData;
        }
        throw new Error(
          "The resource you are looking for is either expired or invalid."
        );
      })
      .catch(() => {
        throw new Error(
          "The resource you are looking for is either expired or invalid."
        );
      });
  }, options);
};
