import { Button, Col, Image, Tooltip } from "antd";
import classNames from "classnames";
import { ImportCurve } from "iconsax-react";
import { useDownloadFolder, useFolderDownloadData } from "./query";
import folderIcon from "../assets/icons/folder.svg";
import classes from "../styles/modules/SourceFiles.module.scss";
import React from "react";
import { VIEW_MODE } from "utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function FolderItem({ folder, onClick, mode }) {
  const isListView = mode === VIEW_MODE.LIST;
  const { revisionid } = useParams();
  const { mutateAsync: downloadFolder, isLoading: downloading } =
    useDownloadFolder({
      onSuccess: (res) => {
        window.location = res.fileUrl;
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  const {
    mutateAsync: downloadFolderData,
    isLoading: downloadFolderDataLoading,
  } = useFolderDownloadData({});

  const handleFolderDownload = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const res: any = await downloadFolderData({
        revisionid,
        folderName: folder.folder_name,
        folderId: folder.id,
      });
      if (Object.keys(res.file_structure).length == 0) {
        toast.info("No files to download");
        return;
      }
      const data = {
        isFromKimpHub: true,
        medias: Object.entries(res.file_structure)
          .map(([folderName, folderFiles]: [string, any[]]) => {
            return folderFiles.map((file) => ({
              path: `${folderName}/`,
              media: {
                file_name: file.split("/").at("-1"),
                file_path: file,
              },
            }));
          })
          .flat(1),
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      downloadFolder(data);
    } catch (err) {
      toast.error("Failed to download.");
    }
  };

  return isListView ? (
    <tr key={folder.id} onClick={onClick}>
      <td>
        <Image
          width={60}
          src={folderIcon}
          preview={false}
          className={classes.cardPreview}
        />
        <span className={classes.folder_name}>{folder.folder_name}</span>
      </td>
      <td>
        <button
          className={classes.download_text_btn}
          onClick={handleFolderDownload}
        >
          Download Folder
        </button>
      </td>
    </tr>
  ) : (
    <Col
      key={folder.id}
      xs={24}
      sm={24}
      md={8}
      lg={6}
      xl={6}
      className={classes.folderCard}
    >
      <button
        className={classNames([
          "w-100 d-flex align-items-center",
          classes.folderButton,
        ])}
      >
        <div
          className={classNames([
            "w-100 d-flex align-items-center",
            classes.folderCont,
          ])}
          role="button"
          onClick={onClick}
        >
          <Image
            width={60}
            src={folderIcon}
            preview={false}
            className={classes.cardPreview}
          />
          <div className={classNames(["w-100", classes.FolderFooter])}>
            <h6>{folder.folder_name}</h6>
          </div>
        </div>
        <Tooltip title="Download">
          <Button
            className={classes.dropdown_btn}
            loading={downloadFolderDataLoading || downloading}
            onClick={handleFolderDownload}
          >
            {" "}
            <ImportCurve size="17" color="#fff" />{" "}
          </Button>
        </Tooltip>
      </button>
    </Col>
  );
}
