import React, { useEffect } from "react";

export default function useEffectOnce(condition, callback, def = []) {
  const didRun = React.useRef(false);

  useEffect(() => {
    if (condition && !didRun.current) {
      callback();
      didRun.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, condition, ...def]);
}
