import React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";

import { styleDefObj } from "./style-customize";

export default function KimpFileIcon({
  customIcon = false,
  extension,
  // glyphColor = false,
  labelColor = false,
  labelUppercase = true,
  foldColor = "#F1731E",
  ...props
}) {
  if (!customIcon) {
    const ext = extension.toLowerCase().replace(/\s+/g, "");
    const customDefaultLabelColor = styleDefObj[ext]
      ? styleDefObj[ext].labelColor ?? "#777"
      : "#777";

    // Library defined default labelCOlor
    // const libDefaultGlyphColor = defaultStyles[ext] && defaultStyles[ext].labelColor;
    return (
      <FileIcon
        extension={ext}
        // glyphColor={glyphColor || (libDefaultGlyphColor ?? customDefaultLabelColor)}
        labelColor={labelColor || customDefaultLabelColor}
        labelUppercase={labelUppercase}
        foldColor={foldColor}
        gradientColor="#0000004f"
        labelTextColor="#fffffff0"
        glyphColor="#ffffff8f"
        radius={2}
        fold={false}
        {...props}
        {...defaultStyles[ext]}
        {...styleDefObj[ext]}
      />
    );
  }

  const CustomIcon = customIcon;
  return <CustomIcon {...props} />;
}
