import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SharedFolderView from "./SharedFolderView";

export default function SharedFolderPage() {
  const { folderid } = useParams();
  const [subSelectedFolder, setSubSelectedFolder] = useState<string>(folderid);

  return (
    <SharedFolderView
      viewtype={2}
      onFolderLinkClick={(folderId) => {
        setSubSelectedFolder(folderId + "");
      }}
      subFolderId={subSelectedFolder}
      folderid={folderid}
    />
  );
}
