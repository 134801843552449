import SharedPage from "./SharedPage";
import { Route, Routes } from "react-router-dom";
import LOGO from "assets/logo.png";

function HomePage() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-vh-100">
      <img width={250} className="mb-5" src={LOGO} alt="logo" />
      <h1 className="font-weight-bold">Design Source Files</h1>
    </div>
  );
}

function Router() {
  return (
    <Routes>
      <Route path="/source/*" element={<SharedPage />} />
      <Route path="/*" element={<HomePage />} />
    </Routes>
  );
}

export default Router;
