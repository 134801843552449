import React from "react";
import { Button } from "react-bootstrap";

import Modal from "../Modal/Modal";
import classes from "./Confirmation.module.scss";

function Confirmation({ open, description, onSubmit, onClose }) {
  return (
    <Modal
      bodyProps={{
        style: {
          padding: 10,
        },
      }}
      showHeader={false}
      destroyOnClose
      closable={false}
      footer=""
      visible={open}
      onCancel={onClose}
    >
      <div className="text-center">
        <p
          className="mb-2 mt-3 text-size-sm font-weight-600"
          style={{ color: "#1B1E23" }}
        >
          {description}
        </p>
        <div className="mt-4 pb-3">
          <Button
            className={classes.cancleBut}
            variant="light"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.confirmBut}
            variant="primary"
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default Confirmation;
