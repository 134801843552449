import React from "react";

type Props = {
  message: string;
};
export default function ErrorMessage({ message, ...props }: Props) {
  return (
    <div
      className="w-100 h-vh-100 d-flex align-items-center justify-content-center"
      {...props}
    >
      <div className="d-flex flex-column text-center">
        <h1 className="font-weight-600 mb-5">404</h1>
        <h4>{message === "404" ? "Page not found" : message}</h4>
      </div>
    </div>
  );
}
