import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../components/Spinner.jsx";

import { Breadcrumb, Col, Empty, Image, Row, Button, Tooltip } from "antd";
import Skeleton from "react-loading-skeleton";
import {
  useDownloadFolder,
  useFolderBreadCrumbs,
  useFolderDownloadData,
  useFolderFiles,
  useFolderSubFolders,
} from "./query";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../styles/modules/SourceFiles.module.scss";
import classNames from "classnames";
import FileView from "./FileView";
import FileViewGrid from "./FileViewGrid";
import { ArrowRight2, Back, Grid2, Grid6, ImportCurve } from "iconsax-react";
import ErrorMessage from "./ErrorMessage";
import useUrlQueryParams, { VIEW_MODE, VIEW_MODE_TYPE } from "../utils";
import FolderItem from "./FolderItem";
import { toast } from "react-toastify";
import useEffectOnce from "./useEffectOnce";

export default function SharedFolderView({
  folderid,
  onFolderLinkClick,
  onBreadCrumbHomeCLick,
  subFolderId,
  viewtype,
}: {
  viewMode?: VIEW_MODE_TYPE;
  setViewMode?: (viewMode?: VIEW_MODE_TYPE) => void;
  folderid: string;
  onFolderLinkClick: (folderId: string) => void;
  onBreadCrumbHomeCLick?: () => void;
  viewtype: number;
  subFolderId: string;
}) {
  const { pathname, search } = useLocation();
  const { autoDownload } = useUrlQueryParams(search) as any;
  const { taskid, revisionid, ukey, uvalid } = useParams();
  const root = document.getElementById("root");
  const {
    data: revisionData,
    error,
    isLoading,
  } = useFolderSubFolders<any, any>({
    folderid,
    revisionid,
    taskid,
    ukey,
    uvalid,
    viewtype,
    subFolderId: subFolderId,
  });

  const {
    data: folderFiles,
    error: fileError,
    isLoading: folderFilesLoading,
  } = useFolderFiles<any, any>({
    subFolderId: subFolderId,
    folderid,
    revisionid,
    taskid,
    ukey,
    uvalid,
    viewtype,
  });

  const { data: breadCrumbs, isLoading: loadingBreadCrumbs } =
    useFolderBreadCrumbs({
      folderid: subFolderId,
      revisionid,
    });

  const {
    mutateAsync: downloadFolderData,
    isLoading: downloadFolderDataLoading,
  } = useFolderDownloadData({});

  const { mutateAsync: downloadFolder, isLoading: downloading } =
    useDownloadFolder({
      onSuccess: (res) => {
        window.location = res.fileUrl;
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  const handleCurrentFolderDownload: (e?: any) => void = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      const currentFolderData = breadCrumbs.at(-1);
      const res: any = await downloadFolderData({
        revisionid,
        folderName: currentFolderData.folder_name,
        folderId: currentFolderData.id,
      });
      if (Object.keys(res.file_structure).length == 0) {
        toast.info("No files to download");
        return;
      }
      const data = {
        isFromKimpHub: true,
        zip_name: `${revisionData?.title_360}`,
        medias: Object.entries(res.file_structure)
          .map(([folderName, folderFiles]: [string, any[]]) => {
            return folderFiles.map((file) => ({
              path: `${folderName}/`,
              media: {
                file_name: file.split("/").at("-1"),
                file_path: file,
              },
            }));
          })
          .flat(1),
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      downloadFolder(data);
    } catch (err) {
      toast.error("Failed to download.");
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  let { view_mode: viewMode } = useUrlQueryParams<{
    view_mode?: VIEW_MODE_TYPE;
  }>(location.search);
  viewMode = viewMode || VIEW_MODE.GRID;

  const activeFolderParent = breadCrumbs?.at(-2);

  useEffectOnce(
    autoDownload && revisionData?.title_360,
    () => {
      handleCurrentFolderDownload();
      const newParams = new URLSearchParams(search);
      newParams.delete("autoDownload");
      navigate(pathname + "?" + newParams.toString());
    },
    [revisionData?.title_360]
  );

  if (isLoading || folderFilesLoading) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Spinner />
      </div>
    );
  }

  if (error || fileError) {
    return (
      <ErrorMessage message={error.message || fileError.message || "404"} />
    );
  }

  return (
    <div className={classes.main}>
      <div
        className="bg-white shadow"
        style={{ borderRadius: 8, margin: "0 20px" }}
      >
        <Row>
          {revisionData?.title_360 && (
            <Col span={24} className={classes.titile360}>
              <h4 className="mb-0 font-weight-600">
                {revisionData?.title_360}
              </h4>
            </Col>
          )}
          <div className={classes.filterWrapper}>
            <Breadcrumb
              style={{
                cursor: "pointer",
              }}
              className="mb-0"
              separator={<ArrowRight2 size="16" color="#101223" />}
            >
              {(onBreadCrumbHomeCLick || activeFolderParent) && (
                <Breadcrumb.Item
                  className="ant-breadcrumb-link d-flex align-items-center"
                  onClick={() => {
                    if (activeFolderParent) {
                      onFolderLinkClick(activeFolderParent.id + "");
                    } else {
                      onBreadCrumbHomeCLick();
                    }
                  }}
                >
                  <Back />
                </Breadcrumb.Item>
              )}

              {onBreadCrumbHomeCLick && (
                <Breadcrumb.Item
                  className="ant-breadcrumb-link home"
                  onClick={() => {
                    onBreadCrumbHomeCLick();
                  }}
                >
                  Home
                </Breadcrumb.Item>
              )}
              {loadingBreadCrumbs && <Skeleton width={200} />}
              {breadCrumbs?.map((item) => (
                <Breadcrumb.Item
                  onClick={() => {
                    onFolderLinkClick(item.id + "");
                  }}
                  key={item.id}
                >
                  {item.folder_name}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <div className="ml-auto d-flex">
              {breadCrumbs && (
                <Tooltip title="Download All">
                  <Button
                    icon={<ImportCurve size="22" />}
                    onClick={handleCurrentFolderDownload}
                    loading={downloadFolderDataLoading || downloading}
                    className={classNames(classes.download_all_btn, "mr-3")}
                  />
                </Tooltip>
              )}
              <Button
                onClick={() =>
                  navigate(
                    `${location.pathname}?view_mode=${
                      viewMode === VIEW_MODE.GRID
                        ? VIEW_MODE.LIST
                        : VIEW_MODE.GRID
                    }`
                  )
                }
                className={classes.gridbtn}
              >
                {viewMode === VIEW_MODE.LIST ? (
                  <Tooltip title="Grid View">
                    <Grid2 size="22" color="#677C99" />
                  </Tooltip>
                ) : (
                  <Tooltip title="List View">
                    <Grid6 size="22" color="#677C99" />
                  </Tooltip>
                )}
              </Button>
            </div>
          </div>
        </Row>

        {revisionData?.revisionsubs?.length === 0 &&
          folderFiles?.revisionfiles.length === 0 && (
            <div className="mx-auto my-4 py-5">
              <Empty
                description={
                  <>
                    <h5>No design files are currently available.</h5>
                    <h6>Your team will upload your design files ASAP.</h6>
                  </>
                }
              />
            </div>
          )}

        <Row className="p-4">
          <div className={classes.firstStage}>
            {viewMode === VIEW_MODE.GRID &&
              revisionData?.revisionsubs.length > 0 && (
                <>
                  <span className="folders">Folders</span>
                  <Row style={{ marginBottom: 20, gap: "20px" }}>
                    {revisionData?.revisionsubs.map((folder) => {
                      return (
                        <FolderItem
                          key={folder.id}
                          folder={folder}
                          mode={viewMode}
                          onClick={() => onFolderLinkClick(folder.id)}
                        />
                      );
                    })}
                  </Row>
                </>
              )}
            {viewMode === VIEW_MODE.LIST &&
              revisionData?.revisionsubs.length > 0 && (
                <div
                  className={classNames([
                    classes.cardPreview_listview,
                    "mb-5 px-0",
                  ])}
                >
                  <table className="theme-table">
                    <thead>
                      <tr>
                        <th>Folders</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {revisionData?.revisionsubs.map((folder) => {
                        return (
                          <FolderItem
                            key={folder.id}
                            folder={folder}
                            mode={viewMode}
                            onClick={() => onFolderLinkClick(folder.id)}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
          <div className={classes.firstStage}>
            {viewMode === VIEW_MODE.GRID && (
              <div className={classes.imagePreviewGridview}>
                <Row gutter={24}>
                  {folderFiles?.revisionfiles.length > 0 && (
                    <>
                      <span className="folders">Files</span>
                      <Image.PreviewGroup
                        preview={{
                          onVisibleChange: (visible) =>
                            visible
                              ? root.classList.add("image-preview-open")
                              : root.classList.remove("image-preview-open"),
                          countRender: (current) =>
                            folderFiles?.revisionfiles[current - 1]?.filename,
                        }}
                      >
                        {folderFiles?.revisionfiles.map((file) => (
                          <FileViewGrid key={file.id} file={file} />
                        ))}
                      </Image.PreviewGroup>
                    </>
                  )}
                </Row>
              </div>
            )}
            {viewMode === VIEW_MODE.LIST &&
              folderFiles?.revisionfiles.length > 0 && (
                <div className={classes.imagePreviewListview}>
                  <table className="theme-table">
                    <thead>
                      <tr>
                        <th style={{ maxWidth: 300 }}>Files</th>
                        <th>Dimension</th>
                        <th>Size</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Image.PreviewGroup
                        preview={{
                          onVisibleChange: (visible) =>
                            visible
                              ? root.classList.add("image-preview-open")
                              : root.classList.remove("image-preview-open"),
                          countRender: (current) =>
                            folderFiles?.revisionfiles[current - 1]?.filename,
                        }}
                      >
                        {folderFiles?.revisionfiles.map((file) => (
                          <FileView key={file.id} file={file} />
                        ))}
                      </Image.PreviewGroup>
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </Row>
      </div>
    </div>
  );
}
