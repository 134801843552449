import * as React from "react";
import Confirmation from "./Confirmation";

const ConfirmationServiceContext = React.createContext(Promise);

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext);

export function ConfirmationServiceProvider({ children }) {
  const [confirmationState, setConfirmationState] = React.useState(null);

  const awaitingPromiseRef = React.useRef();

  const openConfirmation = React.useCallback((options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    if (!confirmationState.handleClose) {
      setConfirmationState(null);
    } else {
      confirmationState.handleClose(() => {
        setConfirmationState(null);
      });
    }
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    if (!confirmationState.handleConfirm) {
      setConfirmationState(null);
    } else {
      confirmationState.handleConfirm(() => {
        setConfirmationState(null);
      });
    }
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <Confirmation
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
}
