import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid2, Grid6 } from "iconsax-react";
import Spinner from "../components/Spinner.jsx";
import { Col, Empty, Row, Button, Tooltip } from "antd";
import { useRevisionFolders } from "./query";
import SharedFolderView from "./SharedFolderView";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../styles/modules/SourceFiles.module.scss";
import classNames from "classnames";
import ErrorMessage from "./ErrorMessage";
import useUrlQueryParams, { VIEW_MODE, VIEW_MODE_TYPE } from "../utils";
import FolderItem from "./FolderItem";

export default function SharedRevisionPage() {
  const [activeFolder, setActiveFolder] = useState<string | undefined>();
  const navigate = useNavigate();
  const location = useLocation();
  const { taskid, revisionid, ukey, uvalid } = useParams();
  let { view_mode: viewMode } = useUrlQueryParams<{
    view_mode?: VIEW_MODE_TYPE;
  }>(location.search);
  viewMode = viewMode || VIEW_MODE.GRID;

  const {
    data: revisionData,
    error,
    isLoading,
  } = useRevisionFolders<any, any>({
    revisionid,
    taskid,
    ukey,
    uvalid,
  });

  if (isLoading) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={error.message || "404"} />;
  }

  if (activeFolder) {
    return (
      <SharedFolderView
        viewMode={viewMode}
        viewtype={1}
        onBreadCrumbHomeCLick={() => {
          setActiveFolder(undefined);
        }}
        onFolderLinkClick={(folderId) => {
          setActiveFolder(folderId);
        }}
        subFolderId={activeFolder}
        folderid={activeFolder}
      />
    );
  }

  return (
    <div className={classes.main}>
      <div
        className="bg-white shadow"
        style={{ borderRadius: 8, margin: "0 20px" }}
      >
        {revisionData?.title_360 && (
          <Row>
            <Col span={24} className={classes.titile360}>
              <h4 className="mb-0 font-weight-600">
                {revisionData?.title_360}
              </h4>
            </Col>
            <div className={classes.filterWrapper}>
              <Button
                onClick={() =>
                  navigate(
                    `${location.pathname}?view_mode=${
                      viewMode === VIEW_MODE.GRID
                        ? VIEW_MODE.LIST
                        : VIEW_MODE.GRID
                    }`
                  )
                }
                className={classes.gridbtn}
                style={{ marginLeft: "auto", float: "right" }}
              >
                {viewMode === VIEW_MODE.LIST ? (
                  <Tooltip title="Grid View">
                    <Grid2 size="22" color="#677C99" />
                  </Tooltip>
                ) : (
                  <Tooltip title="List View">
                    <Grid6 size="22" color="#677C99" />
                  </Tooltip>
                )}
              </Button>
            </div>
          </Row>
        )}
        {revisionData?.revisionsubs?.length === 0 && (
          <div className="mx-auto my-4 py-5">
            <Empty
              description={
                <>
                  <h5>No design files are currently available.</h5>
                  <h6>Your team will upload your design files ASAP.</h6>
                </>
              }
            />
          </div>
        )}
        {viewMode === VIEW_MODE.GRID && (
          <Row className="p-4" gutter={[24, 24]}>
            {revisionData?.revisionsubs.map((folder) => {
              return (
                <FolderItem
                  folder={folder}
                  key={folder.id}
                  mode={viewMode}
                  onClick={() => setActiveFolder(folder.id)}
                />
              );
            })}
          </Row>
        )}
        {viewMode === VIEW_MODE.LIST && (
          <div className={classNames([classes.cardPreview_listview, "py-4"])}>
            <table className="theme-table">
              <thead>
                <tr>
                  <th>Folders</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {revisionData?.revisionsubs.map((folder) => {
                  return (
                    <FolderItem
                      folder={folder}
                      key={folder.id}
                      mode={viewMode}
                      onClick={() => setActiveFolder(folder.id)}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
