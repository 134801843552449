import React from "react";
import { Route, Routes } from "react-router-dom";
import SharedFolderPage from "./SharedFolderPage";
import SharedRevisionPage from "./SharedRevisionPage";

export default function SharedPage() {
  return (
    <Routes>
      <Route
        path="/r/:taskid/:revisionid/:ukey/:uvalid"
        element={<SharedRevisionPage />}
      />
      <Route
        path="/f/:taskid/:revisionid/:folderid/:ukey/:uvalid"
        element={<SharedFolderPage />}
      />
    </Routes>
  );
}
