// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMemo } from "react";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";

export function isImage(fileType) {
  if (!fileType) return false;
  const imageFileTypes = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "webp",
    "svg",
    "tif",
    "tiff",
    "ico",
  ];
  return imageFileTypes.includes(fileType.toLowerCase());
}

export const isTifFile = (fileType) =>
  fileType === "image/tiff" ||
  fileType === "image/tif" ||
  fileType === "tif" ||
  fileType === "tiff";

export const VIEW_MODE = {
  GRID: "grid",
  LIST: "list",
} as const;

export type VIEW_MODE_TYPE = TransformInput<typeof VIEW_MODE>;

// eslint-disable-next-line @typescript-eslint/ban-types
export default function useUrlQueryParams<T extends {}>(search) {
  return useMemo(() => {
    const params = new URLSearchParams(search);
    const formatedData = {};

    for (const [key, value] of params.entries()) {
      formatedData[key] = value;
    }

    return formatedData as T;
  }, [search]);
}

export const getImageMeta = (url: string, cb: any) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

export async function downloadFile(key: string, fileName: string) {
  const clientParams = {
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_KEY_SECRET,
    },
  };
  const client = new S3Client(clientParams);
  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: key + "/" + fileName,
    ResponseContentDisposition: `attachment; filename=${fileName}`,
  });

  const presignedUrl = await getSignedUrl(client, command, { expiresIn: 3600 });
  const acceleratedEndpoint = `${process.env.REACT_APP_AWS_BUCKET}.s3-accelerate.amazonaws.com`;
  return presignedUrl.replace(
    process.env.REACT_APP_AWS_BUCKET + ".s3.amazonaws.com",
    acceleratedEndpoint
  );
}

const getFileNameFromUrl = (url) => {
  const urlData = new URL(url);
  const fileName = urlData.pathname.split("/").pop();
  return fileName;
};

const thumbSize = (type) => {
  if (type === "big") {
    return { width: 300, height: 300 };
  } else {
    return { width: 150, height: 150 };
  }
};

const getImageTypeFromUrl = (url) => {
  const urlData = new URL(url);
  const fileType = urlData.pathname.split(".").pop();
  return fileType;
};

export const SERVER_SUPPORTED_FILE = new Map([
  ["ai", "application/postscript"],
  ["bmp", "image/bmp"],
  ["gif", "image/gif"],
  ["ico", "image/x-icon"],
  ["jpg", "image/jpeg"],
  ["png", "image/png"],
  ["ps", "application/postscript"],
  ["psd", "application/octet-stream"],
  ["svg", "image/svg+xml"],
  ["tif", "image/tiff"],
  ["tiff", "image/tiff"],
  ["jpeg", "image/jpeg"],
  ["webp", "image/webp"],
  ["eps", "application/postscript"],
  ["jfif", "image/jpeg"],
  ["raw", "application/octet-stream"],
  ["heic", "image/heic"],
  ["heif", "image/heif"],

  ["fnt", "application/octet-stream"],
  ["indd", "application/x-indesign"],
  ["otf", "font/otf"],
  ["ttf", "font/ttf"],

  ["zip", "application/zip"],
  ["fig", "application/x-xfig"],
  ["mp4", "video/mp4"],
  ["xd", "application/octet-stream"],
  ["mov", "video/quicktime"],

  ["aif", "audio/aiff"],
  ["mid", "audio/midi"],
  ["midi", "audio/midi"],
  ["mp3", "audio/mpeg3"],
  ["mpa", "audio/mpeg"],
  ["ogg", "application/ogg"],
  ["wav", "audio/wav"],
  ["wma", "audio/x-ms-wma"],
  ["wpl", "application/vnd.ms-wpl"],

  ["doc", "application/msword"],
  [
    "docx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  ["odt", "application/vnd.oasis.opendocument.text"],
  ["pdf", "application/pdf"],
  ["rtf", "text/richtext"],
  ["txt", "text/plain"],
  ["wpd", "application/x-wpwin"],

  ["ods", "application/vnd.oasis.opendocument.spreadsheet"],
  ["xls", "application/vnd.ms-excel"],
  ["xlsm", "application/vnd.ms-excel.sheet.macroenabled.12"],
  ["xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],

  ["key", "text/plain"],
  ["odp", "application/vnd.oasis.opendocument.presentation"],
  ["pps", "application/vnd.ms-powerpoint"],
  ["ppt", "application/vnd.ms-powerpoint"],
  [
    "pptx",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ],
]);

export const getThumbnail = (
  url,
  type = "small",
  providedFileType?: string
) => {
  try {
    const fileName = getFileNameFromUrl(url);
    const fileType = providedFileType || getImageTypeFromUrl(url);

    if (!fileType) {
      return url;
    }

    const fileTypeMimetype = SERVER_SUPPORTED_FILE.get(fileType);

    if (
      !["image/jpg", "image/jpeg", "image/png", "image/bmp"].includes(
        fileTypeMimetype
      )
    ) {
      return url;
    }

    if (fileName.endsWith(type)) {
      return url;
    }

    const THUMBNAIL_API_URL = process.env.REACT_APP_THUMBNAIL_DOMAIN;

    return `${THUMBNAIL_API_URL}?url=${url}&width=${
      thumbSize(type).width
    }&height=${thumbSize(type).height}`;
  } catch (err) {
    return url;
  }
};
